import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import queryString from "query-string"
import SEO from "components/shared/seo"
import { OneIDEvent } from "oneid-types"
import { Logo } from "components/shared/Logo"
import { Button } from "components/shared/atoms/Button"
import { Loader } from "components/shared/atoms/Loader"
import { CountDown } from "components/form/CountDown"
import QRCode from "qrcode.react"
import Img from "gatsby-image"
import firebase from "gatsby-plugin-firebase"
import { useStaticQuery, graphql } from "gatsby"
import { theme } from "../styles/theme"
import Bugsnag from "@bugsnag/js"
import defaultBackground from "../../content/assets/mountain-splashscreen.jpg"

const form = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      appstore: file(name: { eq: "appstore" }, extension: { eq: "png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      playstore: file(name: { eq: "playstore" }, extension: { eq: "png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [eventId, setEventId] = useState<string>("")
  const [event, setEvent] = useState<OneIDEvent | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMesssage, setErrorMesssage] = useState("")

  useEffect(() => {
    const parsed = queryString.parse(props.location.search)

    if (typeof parsed.eventId === "string") {
      setEventId(parsed.eventId)
    }

    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)
      try {
        const data = await firebase
          .database()
          .ref("/proservices/" + parsed.eventId)
          .once("value")
          .then(snapshot => {
            return snapshot.val()
          })
        setEvent(data)
      } catch (error) {
        setIsError(true)
        Bugsnag.notify({ name: "Error", message: JSON.stringify(error) })

        setErrorMesssage(JSON.stringify(error))
      }
      setIsLoading(false)
    }

    if (!parsed.eventId) {
      navigate("/")
    } else {
      fetchData()
    }
  }, [])

  if (isError) {
    return (
      <div>
        Une erreur s'est produite<p>{errorMesssage}</p>
      </div>
    )
  }

  console.log("event.startTime", event && event.startTime)

  return (
    <div>
      <LogoContainer>
        <Logo width="125" link="/" color={"#FFFFFFAA"} />
      </LogoContainer>
      <Container>
        {isLoading || !event ? (
          <Loader />
        ) : (
          <>
            <SEO
              title={`OneID | ${event.name}`}
              description={event.description || undefined}
            />
            <ContentContainer>
              <EventName color={event.color || theme.colors.secondary}>
                {event.name}
              </EventName>
              <EventOrganizer>Organisé par : {event.organizer}</EventOrganizer>
              {event.startTime && (
                <CountDownContainer>
                  <CountDown date={event.startTime} />
                </CountDownContainer>
              )}
              <Button
                themeStyle="primary"
                color={event.color || theme.colors.primary}
                link={`oneid://app/newaccount/${eventId}`}
              >
                Je m'inscris avec l'application
              </Button>

              <QRCodeContainer>
                <StyledQRCode value={eventId} size={120} />
              </QRCodeContainer>
              <StoreContainer>
                <div className="element">
                  <a href="https://itunes.apple.com/fr/app/oneid-ton-identit%C3%A9-num%C3%A9rique/id1437710130?mt=8">
                    <Img fixed={data.appstore.childImageSharp.fixed} alt="" />
                  </a>
                </div>
                <div className="element">
                  <a href="https://play.google.com/store/apps/details?id=com.imagineoneid.oneid">
                    <Img fixed={data.playstore.childImageSharp.fixed} alt="" />
                  </a>
                </div>
              </StoreContainer>
            </ContentContainer>
          </>
        )}
      </Container>
      <BgContainer backgroundUrl={event && event.backgroundUrl} />
    </div>
  )
}

export default form

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const BgContainer = styled.div<{ backgroundUrl: string | undefined | null }>`
  border: none;
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  background-blend-mode: multiply, multiply;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 10%,
      rgba(255, 255, 255, 0.25) 100%
    ),
    radial-gradient(
      at top center,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(0, 0, 0, 0.8) 120%
    ),
    ${props =>
      props.backgroundUrl
        ? `url(${props.backgroundUrl})`
        : ` url(${defaultBackground})`}; //"radial-gradient(circle, rgba(142,172,187,1) 0%, rgba(96,125,139,1) 100%)"};

  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-size: cover;
`

const ContentContainer = styled.div`
  display: flex;
  margin: ${props => props.theme.margins.m8} 0;
  max-width: 72rem;
  margin: ${props => props.theme.margins.m20} auto 0 auto;
  flex-direction: column;
  align-items: center;
`

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -62.5px; // should be half of logo width
  text-align: center;
  padding: ${props => props.theme.paddings.p4} 0;
`

const EventName = styled.h1<{ color: string }>`
  color: white;
  text-align: center;
  text-shadow: 4px 4px 0px ${props => props.color},
    7px 7px 0px rgba(0, 0, 0, 0.2);
  font-size: ${props => props.theme.fontSizes.xl6};

  @media (min-width: ${props => props.theme.screens.md}) {
    font-size: ${props => props.theme.fontSizes.xl8};
  }

  @media (min-width: ${props => props.theme.screens.sm}) {
    font-size: ${props => props.theme.fontSizes.xl7};
  } ;
`

const EventOrganizer = styled.p`
  color: white;
  margin: ${props => props.theme.margins.m2} 0;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.sm};

  @media (min-width: ${props => props.theme.screens.md}) {
    font-size: ${props => props.theme.fontSizes.base};
  }
`

const CountDownContainer = styled.div`
  text-align: center;
  margin: ${props => props.theme.margins.m2} 0
    ${props => props.theme.margins.m8} 0;
  font-size: ${props => props.theme.fontSizes.xs};

  @media (min-width: ${props => props.theme.screens.md}) {
    font-size: ${props => props.theme.fontSizes.base};
  }

  @media (min-width: ${props => props.theme.screens.sm}) {
    font-size: ${props => props.theme.fontSizes.sm};
  } ;
`

const QRCodeContainer = styled.div`
  margin: ${props => props.theme.margins.m8} 0;
`

const StyledQRCode = styled(QRCode)`
  padding: ${props => props.theme.paddings.p8};
  background-color: white;
  border-radius: 15px;
`

const Sub = styled.h2`
  margin: ${props => props.theme.margins.m6} 0;
  font-weight: 300;
  font-size: ${props => props.theme.fontSizes.lg};
  color: ${props => props.theme.colors.gray};
`

const SubSmall = styled(Sub)`
  font-size: ${props => props.theme.fontSizes.sm};
`

const StoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.theme.margins.m4} auto;

  .element {
    margin: 0 ${props => props.theme.margins.m4};
  }

  @media (min-width: ${props => props.theme.screens.md}) {
    margin: ${props => props.theme.margins.m4} -${props => props.theme.margins.m4};
  }
`
