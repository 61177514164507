import React, { useState, useEffect } from "react"
import styled from "styled-components"

const second = 1000
const minute = second * 60
const hour = minute * 60
const day = hour * 24

const CountDown = ({ date }: { date: number }) => {
  const [countDown, setCountDown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    let distance = 0

    const interval = setInterval(() => {
      const now = new Date().getTime()

      distance = date - now

      setCountDown({
        days: Math.floor(distance / day),
        hours: Math.floor((distance % day) / hour),
        minutes: Math.floor((distance % hour) / minute),
        seconds: Math.floor((distance % minute) / second),
      })
    }, 1000)

    if (distance < 0) {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Container>
      <ul>
        <li>
          <span>{countDown.days}</span>Jours
        </li>
        <li>
          <span>{countDown.hours}</span>Heures
        </li>
        <li>
          <span>{countDown.minutes}</span>Minutes
        </li>
        <li>
          <span>{countDown.seconds}</span>Secondes
        </li>
      </ul>
    </Container>
  )
}

export { CountDown }

const Container = styled.div`
  color: #fff;
  margin: 0 auto;
  font-weight: 300;

  li {
    display: inline-block;
    list-style-type: none;
    padding: ${props => props.theme.paddings.p4};
    text-transform: uppercase;
  }

  li span {
    display: block;
    font-size: 2rem;
  }
`
